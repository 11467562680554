import React from 'react';
import css from './PrivacyContainer.module.css'

const PrivacyContainer = () => {
    return (
        <div className={css.privacyWrapper}>
            <h5>Privacy Policy</h5>
            <h6>ENTITY</h6>
            <p>Company: WK actabon GmbH a company, operating and registered under the laws of the Federal Republic Germany, with its registered
                headquarters in Elisabeth-Dane-Str. 56, 81829 München, Germany, e-mail: <a href="">office@wunder-job.com</a></p>
            <h6>VALIDITY</h6>
            <p><i>Published 01st April 2024. <br />
                These terms replace and supersede all prior versions. Please take a moment to read through the policy, as amended by us from time to time, which
                is the document between the Company and you, to ensure that you have a complete and clear understanding of the principles and legal obligations
                under which we provide you the Offering and process the data you provided.</i></p>
            <h6>DEFINITIONS</h6>
            <ul>
                <li><b>Offering</b> - means Company’s or its’ affiliates identified herein websites, services, products, applications, software, courses, trainings,
                    forums, workflows, improvements of any kind and associated documentation, including any beta or other test programs, results,
                    provided by us, or obtained by us while ensuring continuous improvement of the Offering or as provided for you specific use, and any
                    provided or shared.</li>
                <li><b>Content</b> – which means text, images, videos, audio, algorithms, software, or other information.</li>
                <li><b>Company</b> – further referenced either as “Company”, “we”, “us” or “our”.</li>
                <li><b>Customer</b> – further referenced either as “you”, “your”, “user” or “client” defined as any individual or organization that has, accesses,
                    or directs the use of, an account or service with us, or is testing or using directly or indirectly, the Offering.</li>
                <li><b>Confidential Information</b> – means any information marked confidential or which would normally under the circumstances be
                    considered as such.</li>
                <li><b>Company Aggregate Data</b> – means aggregated and anonymized statistical and other information from the use of the Offering, which
                    does not identify a specific person, and which may be used for reporting, research, improvement, fraud prevention, compliance, and
                    other reasonable business purposes.
                </li>
            </ul>
            <h6>SCOPE</h6>
            <p>This privacy policy describes how the Company will make use of your information in the context of your use of our Offering (defined as our apps,
                websites, software, support, including documentation, assets or services offered by us) and for marketing, sales, and advertising initiatives. The
                Company is developing the Offering tools focused on improving the industry and overall services provision in the industry of which we or our
                Offering is designed to be used as described in detail on our websites or Offering documentation</p>
            <h6>PARTIES</h6>
            <p>The Company and its affiliates (collectively “COMPANY” or "we," "us" or "our") are the controllers with respect to your data. This means that we
                determine the purpose and manner in which your personal data is processed. The contact entity for any questions regarding how your personal
                data is being processed is indicated herein.</p>
            <p className={css.mb_0}><i><b>Company’s Processing Role</b></i></p>
            <div className={css.tableWrapper}>
                <table className={css.partiesTable}>
                    <tr>
                        <th>COMPANY as Controller</th>
                        <th>COMPANY as Processor</th>
                    </tr>
                    <tr>
                        <td><p>We are the data controller when we collect and use information as
                            described herein for the purpose of our Offering.</p></td>
                        <td><p>We are the data processor when our customers collect and use
                            information as described herein. </p></td>
                    </tr>
                    <tr>
                        <td>In this case we are responsible for determining use of the Offering,
                            and for ensuring that all legal requirements applicable to processing
                            of personal information through our Offering are ensured.</td>
                        <td>In this case the data controller is the customer who is solely
                            responsible for determining how to use the Offering, and for ensuring
                            that all legal requirements applicable to processing of personal
                            information through our Offering are ensured. </td>
                    </tr>
                </table>
            </div>
            <h6>OBJECTIVE</h6>
            <p className={css.mb_0}><b>General use, registration, and customer support</b></p>
            <p className={css.mb_0}>When you register to use our website, create an account, or contact us for support or other offerings, we collect information that identifies you,
                such as:</p>
            <div className={css.tableWrapper}>
                <table>
                    <tr>
                        <th>Category</th>
                        <th>Type</th>
                    </tr>
                    <tr>
                        <th rowSpan="2">Natural or Legal Person</th>
                        <td><p>Name; Date of birth; Company name; Email address; Telephone number; Address; Country;</p></td>
                    </tr>
                    <tr>
                        <table className={css.innerTable}>
                            <tr>
                                <td>Recruitment</td>
                                <td>Information in resume/cv, (schools or previous employment) along with other relevant
                                    information</td>
                            </tr>
                        </table>
                    </tr>
                    <tr>
                        <th>Hardware</th>
                        <td>IP address; Mobile Device ID;</td>
                    </tr>
                    <tr>
                        <th>Financial</th>
                        <td>Payment/billing information (for paid services); Eligibility information (e.g., for free apps);</td>
                    </tr>
                    <tr>
                        <th>Activity</th>
                        <td>People with whom you communicate or share content or requests through our Offering; Types of apps
                            and websites of interest; Activity on third-party sites and apps that use our services; Types of industries
                            and services of interest; Content uploaded with or to our Offering; and Content of customer support
                            communications.</td>
                    </tr>
                </table>
            </div>
            <p>We may combine information provided by you to ensure accuracy of the data with information from third party sources, and we may also collect
                information to detect, prevent, or otherwise address fraud, security, or technical issues, as well as to protect against harm to the rights, property
                or safety of our company, employees, users, vulnerable categories, or the public.</p>
            {/* Website */}
            <p className={css.mb_0}><b>Website</b></p>
            <p className={css.mb_0}>We collect information about how you use our website. This information may be associated with your device or browser, or it may be associated
                with your Offering account and includes:
            </p>
            <div className={css.tableWrapper}>
                <table>
                    <tr>
                        <th>Category</th>
                        <th>Type</th>
                    </tr>
                    <tr>
                        <th>Hardware</th>
                        <td>IP address; Mobile Device ID; Type of browser and device;</td>
                    </tr>
                    <tr>
                        <th>Route</th>
                        <td>Webpage that led you to our website;</td>
                    </tr>
                    <tr>
                        <th>Activity</th>
                        <td>Search terms entered into a search engine which lead you to our website; Use and navigation of
                            websites and apps (collected through cookies and similar technologies, or by our servers when you are
                            logged in to the app or website); Analysis of your content (e.g., documents, workflows, photos, videos,
                            activity logs, other data points and direct feedback from you) which is sent or received using an online
                            feature of our website, or which is stored on our servers (e.g., usage logs regarding the use of the
                            Offering, including logins and other actions taken, time stamps, and other usage data).</td>
                    </tr>
                </table>
            </div>
            {/* Emails and texts */}
            <p className={css.mb_0}><b>Emails and texts</b></p>
            <p>Emails we send you may include web beacons through which we see if you have received or opened the email, or clicked a link in the email (you
                can opt out from collection and receiving our marketing).
            </p>
            {/* Advertising */}
            <p className={css.mb_0}><b>Advertising</b></p>
            <p className={css.mb_0}>We may advertise online, including displaying our ads to you on other companies' websites and on social media, such as Facebook or LinkedIn.
                Our website uses cookies and similar technologies that allow us to gather additional information to measure and improve the effectiveness of
                our advertising, such as:
            </p>
            <div className={css.tableWrapper}>
                <table className={css.threeColumnTable}>
                    <tr>
                        <td>Which ads are displayed</td>
                        <td>Which ads are clicked on</td>
                        <td>Where the ad was displayed</td>
                    </tr>
                </table>
            </div>
            <p>We use the information we collect about you for the following purposes:</p>
            <div className={css.tableWrapper}>
                <table>
                    <tr>
                        <th>Purpose</th>
                        <th>Information</th>
                    </tr>
                    <tr>
                        <td><b>To take steps linked to a contract: </b>
                            this is relevant where you register to
                            use our Offering (whether paid, or
                            as a free trial). This includes:</td>
                        <td>
                            <ul>
                                <li>Providing you with the requested websites;</li>
                                <li>Verifying your identity;</li>
                                <li>Processing payments;</li>
                                <li>Sending you communications (for example, related to payments);</li>
                                <li>Sending communication related to the Offering; and</li>
                                <li>Providing customer service or support.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            As required by us to conduct our
                            business and pursue our <b>legitimate
                                interests,</b> in particular:
                        </td>
                        <td>
                            <ul>
                                <li>Providing you with the products for which you have registered; </li>
                                <li>Analyzing your use and measuring effectiveness of our Offering;</li>
                                <li>Sending you information about our Offering and similar;</li>
                                <li>For various events;</li>
                                <li>For recruitment;</li>
                                <li>Sharing your information with 3rd parties for their own marketing purposes (where your
                                    consent is not required);</li>
                                <li>Analyzing your provided use and content to customize marketing communications (where
                                    your consent is not required);</li>
                                <li>Analyzing your content through techniques such as machine learning to improve our
                                    Offering (where your consent is not required);</li>
                                <li>Create aggregated statistical data, derived non-personal data, or anonymized or
                                    pseudonymized data (rendered non-personal and non-identifiable), which we or our
                                    partners or customers may use to provide and improve our Offering.</li>
                                <li>Detecting, preventing, or otherwise addressing problems, fraud, security or technical
                                    issues, as well as protecting against harm to the rights, property or safety of the company,
                                    our users, or the public;</li>
                                <li>Conducting surveys and market research (we will only do this with your permission where
                                    consent is required);</li>
                                <li>Investigating and responding to any comments or complaints;</li>
                                <li>Checking the validity of the account number and card number for which we may use third
                                    parties;</li>
                                <li>If we merge with or are acquired by another company, sell a website or business unit, or if
                                    all or a substantial portion of our assets are acquired by another company, your information
                                    will likely be disclosed to our advisers and any other prospective purchaser's advisers and
                                    will be one of the assets that is transferred to the new owner; and</li>
                                <li>In connection with legal claims and regulatory purposes as necessary (including
                                    government agency requests or litigation).</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>Where we process your information based on legitimate interests, you can object to this processing in certain circumstances. In such cases,
                            we will cease processing information unless we have compelling legitimate grounds to continue processing or where it is needed for legal
                            reasons.</td>
                    </tr>
                    <tr>
                        <td>Where required, <b>when you give us
                            your consent</b> (which you can
                            withdraw at any time) or otherwise
                            consistent with your choices:</td>
                        <td>
                            <ul>
                                <li>Sending you information about our Offering, special offers and sharing your information
                                    with third parties for their own marketing purposes;</li>
                                <li>Placing cookies and using similar technologies on our website and in email communications,
                                    in accordance with this policy and the information provided to you when those
                                    technologies are used;</li>
                                <li>Accessing information stored on your device relating to your use of the website and crash
                                    reports; and</li>
                                <li>Analyzing your content using techniques such as machine learning in order to improve our
                                    services and the user experience;</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>For <b>legal reasons:</b></td>
                        <td>
                            <ul>
                                <li>Responding to requests by government or law enforcement authorities conducting an
                                    investigation or to detect, prevent, or otherwise address misuse, fraud, security, potential
                                    illegal activities, or technical issues and software piracy.
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>Where this processing and these disclosures are not strictly required by law, we may rely on our legitimate interests and those of third parties
                            described above.</td>
                    </tr>
                </table>
            </div>
            {/* Sharing with other Data Controllers */}
            <p className={css.mb_0}><b>Sharing with other Data Controllers</b></p>
            <p className={css.mb_0}>We may share your personal information within our group of companies (as identified on our website, if any) for the above purposes. We may
                also share your personal information with other third-party data controllers. The types of third parties your information may be shared with
                include:
            </p>
            <div className={css.tableWrapper}>
                <table className={css.sharingTable}>
                    <tr>
                        <td>contractors</td>
                        <td>vendors</td>
                        <td>resellers</td>
                        <td>partners</td>
                        <td>advertisers</td>
                        <td>research</td>
                        <td>ISPs</td>
                        <td>fraud monitoring
                            providers</td>
                        <td>Infrastructure
                            providers</td>
                    </tr>
                </table>
            </div>
            <p>Third-party data controllers may also use our Offering to collect and process your personal information. If you are using an email address that is
                associated with a business domain to use our Offering, or if you were invited by a business, we may share your information to that business.</p>
            <p>We are working with the third party provider <a className={css.linkText} href="https://www.sharetribe.com/">www.sharetribe.com</a> to run our HR marketplace on a shared configuration scenario, including the
                following third party services: Airbrake, USA, AWS (Amazon Web Services, US East data center and EU), Chargebee, USA (only for Sharetribe's
                paying customers, not end-users), Convox, USA, Github, USA, Google Workspace, Google Maps Platform, Google Analytics and Google Tag
                manager, USA,Imgix, USA, Intercom, USA, Loggly, USA, Mailchimp, USA (only for customers and prospects, not end-users), NewRelic, USA,
                OpsGenie, USA, PayPal, USA, SendGrid, USA, MailerSend, USA, Sentry, USA, Stripe, USA, Typeform, USA, Unsplash, Canada and the following list
                of cookies and tracking services used: Google reCAPTCHA (abuse and fraud protection), Smartlook analytics (on Sharetribe Console, to help us
                improve user experience), Refersion (for affiliate marketing), Intercom (for customer support), Google Tag Manager (for analytics), TypeKit (for
                webfonts)</p>
            <p>You will find their overview of data security and privacy with this link “<a href='#'>Overview of data security and privacy at Sharetribe</a>”.</p>
            <p className={css.mb_0}><b>Sharing for Fraud Prevention, Safety and Security Purposes</b></p>
            <p>We will share personal information with companies, organizations or individuals outside of the Company if we have a good-faith belief that access,
                use, preservation or disclosure of the information is reasonably necessary to detect, prevent, or otherwise address fraud, security or technical
                issues, as well as to protect against harm to the rights, property or safety of the company and our employees, our users, vulnerable categories or
                the public as required or permitted by law.</p>
            <p className={css.mb_0}><b>Sharing with Data Processors</b></p>
            <p>We will also share your personal information with companies (which we ask to protect your personal information similar to this policy) that help
                us run our business by processing personal information on behalf of the Company for the purposes identified above. Such companies include
                providers of customer support services, business development, payment processing services, fraud monitoring and prevention, email, social
                media, and other marketing, service providers, and hosting services. </p>
            {/* Other Information Sharing */}
            <p><b>Other Information Sharing</b></p>
            <p className={css.mb_0}>We may also share your personal information based on:</p>
            <div className={css.tableWrapper}>
                <table>
                    <tr>
                        <th>Consent</th>
                        <td>When you agree to the sharing.</td>
                    </tr>
                    <tr>
                        <th>Legal Process </th>
                        <td>When we have a good faith belief that we are required to provide information in response to a
                            subpoena, court order, or other applicable law or legal process, or to respond to an emergency.</td>
                    </tr>
                    <tr>
                        <th>Corporate Restructuring </th>
                        <td>Merger or acquisition by another company, sell of all, or a substantial portion of our assets or business
                            to another company, your information will likely be disclosed to our advisers and any prospective
                            purchaser's advisers and will be one of the assets that is transferred to the new owner.</td>
                    </tr>
                    <tr>
                        <th>Statistics</th>
                        <td>We may share or publish aggregate information that doesn’t specifically identify you, such as statistical
                            information about visitors to our websites or statistical information about how customers use the
                            website.</td>
                    </tr>
                </table>
            </div>
            {/* RESPONSIBILITIES */}
            <h6>RESPONSIBILITIES</h6>
            <p>To protect personal data we employ administrative, technical, and physical security controls where appropriate, such as encryption, 2-step
                verification, and strict contractual confidentiality obligations for employees and contractors. Your personal information and files are stored on
                our servers and the servers of companies we hire to provide services to us, such as infrastructure providers. The main locations where we process
                your personal information are in the locations where our infrastructure partners are based or from where they provide the infrastructure services
                we require. We may also transfer personal information to other countries in the world where our Offering may be available. We carry out these
                transfers in compliance with applicable laws – for example, by putting data transfer agreements in place to help protect your personal
                information. We rely on one or more of the following legal mechanisms to ensure adequate protection such as for our EU based users: European
                Commission approved Standard Contractual Clauses, Privacy Shield, your consent, or similar mechanisms. A copy of the relevant mechanism can
                be provided for your review upon request, using the contact details. </p>
            <p>We process your personal data in accordance with the applicable law and you have the right:
            </p>
            <div className={css.tableWrapper}>
                <table>
                    <tr>
                        <th>Right</th>
                        <th>Action</th>
                    </tr>
                    <tr>
                        <th>Access</th>
                        <td>
                            <ul>
                                <li>you have the right to obtain a confirmation whether or not we process your personal data;</li>
                                <li>to have access to the type of personal data and to the conditions of processing;</li>
                                <li>you have the right to obtain the personal information you provide to us for a contract or
                                    with your consent in a structured, machine-readable format.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Rectification</th>
                        <td>
                            <ul>
                                <li>you have the right to request, the rectification of inaccurate or incomplete personal data
                                    which we have about you</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Erasure</th>
                        <td>
                            <ul>
                                <li>you have the right to request erasure of your personal data in case</li>
                            </ul>
                            <ul className={css.erasureList}>
                                <li>the data are no longer necessary for their original purpose (and no new lawful
                                    purpose exists),</li>
                                <li>we initially process your data based on your consent and you withdraw your consent
                                    and therefore no lawful ground exists anymore, </li>
                                <li>you object and we have no overriding grounds for continuing the processing,</li>
                                <li>the data have been processed unlawfully, </li>
                                <li>erasure is necessary for compliance with applicable law</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Restriction</th>
                        <td>
                            <ul>
                                <li>you have the right to ask for the restriction of processing in cases where you consider that
                                    the personal data processed is inaccurate or unlawful</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Withdraw</th>
                        <td>
                            <ul>
                                <li>you have the right to object to the data processing and to object at any moment to the data
                                    processing for direct marketing purposes and the right not to be subject to a decision based
                                    solely on automated processing</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Portability</th>
                        <td>
                            <ul>
                                <li>you have the right to ask us to port your information to another controller in a structured,
                                    machine-readable format</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Complain</th>
                        <td>
                            <ul>
                                <li>you have the right to file a complaint with the national Data Protection Authority and the
                                    right to address to the competent courts of law.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Withdrawing consent or objecting
                            to direct marketing</th>
                        <td>
                            <ul>
                                <li>We and the companies we hire may use your information to provide you with information and offers
                                    related to our Offering. Where we rely on your consent, you will always be able to withdraw that
                                    consent, although we may have other legal grounds for processing your information for other
                                    purposes, such as those set out above. In some cases, we are able to send you direct marketing without
                                    your consent, where we rely on our legitimate interests. You have the right to opt-out of direct
                                    marketing, at any time by: Updating your preferences in your account; Clicking the unsubscribe link at
                                    the bottom of our marketing emails; or E-mail us using the details provided here.</li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
            <p>In addition to the rights above, see <a href="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?division=3.&part=4.&lawCode=CIV&title=1.81.5">California Consumer Privacy Rights</a> for additional information if you are based in California. Please note that
                we do not sell the personal information we collect to other parties.</p>
            {/* TERM */}
            <h6>TERM</h6>
            <p>We will store your personal data for the period of time necessary to achieve the purposes described in this policy and in accordance with our
                legal obligations, our contractual obligations or the industry practices. When you register for an account, we process and keep most personal
                information we have about you for as long as you are an active user. When you close your account, we begin deleting (in accordance with our
                procedures, which may involve archiving, anonymizing or destroying it) certain personal information that we no longer have a business reason to
                retain. However, we will retain personal information related to our contract and business transactions with you from the termination of our
                agreement with you, in accordance with the applicable law.</p>
            <p>Where we process personal information for marketing purposes or with your consent, we process the information until you request us to stop
                the processing. We also keep a permanent record of the fact that you have asked us not to send you direct marketing or to process your
                information so that we can respect your request in future.</p>
            {/* ADDITIONAL */}
            <h6>ADDITIONAL</h6>
            <p>We may update this privacy policy (or other related documents) to allow the Company to accommodate new technologies, industry practices,
                regulatory requirements or for other purposes. We will change the "last updated" date at the top of this policy and the revised policy will be
                posted to this page so that you are aware of the information we collect, how we use it, and under what circumstances we may disclose it. Under
                certain circumstances (for example with certain material changes) we will provide notice to you of these changes and, where required by
                applicable law, we will obtain your consent. Notice may be by email to you, by posting a notice of such changes on our apps and websites, or by
                other means consistent with applicable law. </p>
            <p>We also use “web beacons” (or “tags” bits of programming code included in web pages, emails, and ads that notify us or our partners when those
                web pages, emails, or ads have been viewed or clicked on) to help deliver cookies and gather usage and performance data. Our Offering may
                include web beacons, cookies, or similar technologies from third-party service providers. Cookies are small text files placed on your device to
                store data that can be recalled by a web server in the domain that placed the cookie. We use cookies and similar technologies for storing and
                honoring your preferences and settings, enabling you to sign in, combating fraud, analyzing how our Offering performs, remembering your
                preferences, measuring your use of our Offering, understanding your interests so we can provide you more relevant content, running the solutions
                that help our customers improve the security and performance of their websites and digital infrastructure. Depending on the purpose for which
                they are used cookies can be</p>
            {/* essential */}
            <p><b>essential</b> (for the functionality of the website)</p>
            <div className={css.tableWrapper}>
                <table className={css.threeColumnTable}>
                    <tr>
                        <td>Place</td>
                        <td>Name</td>
                        <td>Owner</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            {/* functional */}
            <p><b>functional</b> (for enhancement of your experience and remembering your preferences)</p>
            <div className={css.tableWrapper}>
                <table className={css.threeColumnTable}>
                    <tr>
                        <td>Place</td>
                        <td>Name</td>
                        <td>Owner</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            {/* analytical */}
            <p><b>analytical</b> (for understanding how the website is used)</p>
            <div className={css.tableWrapper}>
                <table className={css.threeColumnTable}>
                    <tr>
                        <td>Place</td>
                        <td>Name</td>
                        <td>Owner</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            {/* advertising */}
            <p><b>advertising</b> (for providing offers and services)</p>
            <div className={css.tableWrapper}>
                <table className={css.threeColumnTable}>
                    <tr>
                        <td>Place</td>
                        <td>Name</td>
                        <td>Owner</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <p>Cookies can also be “first”, or “third” party based on the website or domain placing it. Third party cookies are set by third parties’ websites or
                domains that you are visiting and are other entities than the Company. You have the tools to control the data collected by cookies, web beacons,
                and similar technologies. For example, you can use the settings in your internet browser to limit how the websites you visit are able to use cookies
                and to withdraw your consent by clearing or blocking cookies. </p>
            <p>If you have a privacy question or request, please contact us. </p>
        </div>
    );
};

export default PrivacyContainer;