import React from 'react';
import PropTypes, { shape } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton } from '../../../components';

import css from '../PageBuilder.module.css';

const CompanyContractComponent = props => (
    <FinalForm
        {...props}
        onSubmit={()=>{}}
        render={fieldRenderProps => {
            const {
                rootClassName,
                className,
                formId,
                inProgress,
                invalid,
                intl,
                handleSubmit,
                isLoading
            } = fieldRenderProps;

            const classes = classNames(rootClassName || css.root, className);
            const submitInProgress = inProgress;
            const submitDisabled = invalid || submitInProgress

            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    <div className={css.welcomeModalContent}>

                        <label className={css.selectLabel}>Get your PDF contract via yousign</label>
                        {isLoading ? <div>Please wait Loading...</div> : <PrimaryButton type="submit" inProgress={submitInProgress} >
                            Get Contract and Sign
                        </PrimaryButton>}
                    </div>

                </Form>
            );
        }}
    />
);

CompanyContractComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

CompanyContractComponent.propTypes = {
    inProgress: bool,

    // from injectIntl
    intl: intlShape.isRequired,
};


const CompanyContract = compose(injectIntl)(CompanyContractComponent);
CompanyContract.displayName = 'Company_Contract';

export default CompanyContract;