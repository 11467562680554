import React, { useState } from 'react';

import { IconSpinner, LayoutComposer, Modal } from '../../components/index.js';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer.js';
import FooterContainer from '../FooterContainer/FooterContainer.js';

import { validProps } from './Field';

import SectionBuilder from './SectionBuilder/SectionBuilder.js';
import StaticPage from './StaticPage.js';

import css from './PageBuilder.module.css';
import css2 from './PrivacyPage.module.css';
import { compose } from 'redux';
import { connect } from 'react-redux';
import CompanyContract from './CompanyContract/CompanyContract.js';
import { YouSignActivateSignature, YouSignAddSigner, YouSignInitiate } from '../../util/api.js';
import { func, shape } from 'prop-types';
import { intlShape } from '../../util/reactIntl.js';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck.js';
import { updateProfile } from '../EditListingPage/EditListingPage.duck.js';
import moment from 'moment';

const getMetadata = (meta, schemaType, fieldOptions) => {
  const { pageTitle, pageDescription, socialSharing } = meta;

  // pageTitle is used for <title> tag in addition to page schema for SEO
  const title = validProps(pageTitle, fieldOptions)?.content;
  // pageDescription is used for different <meta> tags in addition to page schema for SEO
  const description = validProps(pageDescription, fieldOptions)?.content;
  // Data used when the page is shared in social media services
  const openGraph = validProps(socialSharing, fieldOptions);
  // We add OpenGraph image as schema image if it exists.
  const schemaImage = openGraph?.images1200?.[0]?.url;
  const schemaImageMaybe = schemaImage ? { image: [schemaImage] } : {};
  const isArticle = ['Article', 'NewsArticle', 'TechArticle'].includes(schemaType);
  const schemaHeadlineMaybe = isArticle ? { headline: title } : {};

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org (This template uses JSON-LD format)
  //
  // In addition to this schema data for search engines, src/components/Page/Page.js adds some extra schemas
  // Read more about schema:
  // - https://schema.org/
  // - https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': schemaType || 'WebPage',
    description: description,
    name: title,
    ...schemaHeadlineMaybe,
    ...schemaImageMaybe,
  };

  return {
    title,
    description,
    schema: pageSchemaForSEO,
    socialSharing: openGraph,
  };
};

const LoadingSpinner = () => {
  return (
    <div className={css.loading}>
      <IconSpinner delay={600} />
    </div>
  );
};

//////////////////
// Page Builder //
//////////////////

/**
 * PageBuilder can be used to build content pages using page-asset.json.
 *
 * Note: props can include a lot of things that depend on
 * - pageAssetsData: json asset that contains instructions how to build the page content
 *   - asset should contain an array of _sections_, which might contain _fields_ and an array of _blocks_
 *     - _blocks_ can also contain _fields_
 * - fallbackPage: component. If asset loading fails, this is used instead.
 * - options: extra mapping of 3 level of sub components
 *   - sectionComponents: { ['my-section-type']: { component: MySection } }
 *   - blockComponents: { ['my-component-type']: { component: MyBlock } }
 *   - fieldComponents: { ['my-field-type']: { component: MyField, pickValidProps: data => Number.isInteger(data.content) ? { content: data.content } : {} }
 *     - fields have this pickValidProps as an extra requirement for data validation.
 * - pageProps: props that are passed to src/components/Page/Page.js component
 *
 * @param {Object} props
 * @returns page component
 */
const PageBuilder = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    fallbackPage,
    schemaType,
    options,
    currentUser,
    history,
    onManageDisableScrolling,
    updatedcontract,
    ...pageProps
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [contractModalOpen, setContractModalOpen] = useState(false);
  const emailVerified =
    currentUser && currentUser.id && currentUser.id.uuid && currentUser.attributes.emailVerified;
  if (!pageAssetsData && fallbackPage && !inProgress && error) {
    return fallbackPage;
  }

  // Page asset contains UI info and metadata related to it.
  // - "sections" (data that goes inside <body>)
  // - "meta" (which is data that goes inside <head>)
  const { sections = [], meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta, schemaType, options?.fieldComponents);

  const layoutAreas = `
    topbar
    main
    footer
  `;

  const { companyName, userRole, organisationcontract = { yousignId: '', signature_link: '' } } =
    currentUser?.attributes?.profile?.publicData || {};
  const medium_company_templete_id = process.env.REACT_APP_MEDIUM_COMPANY_PRODUCTION_TEMPLETE_ID;
  const large_company_templete_id = process.env.REACT_APP_LARGE_COMPANY_PRODUCTION_TEMPLETE_ID;
  const template_id =
    userRole === 'medium_company' ? medium_company_templete_id : large_company_templete_id;
  console.log('template_id>>>>', template_id); //has to be check on live if it is picking new one or not
  const tz = moment.tz.guess();

  const signInitiateData = {
    name: companyName?.trim(),
    delivery_mode: 'email',
    reminder_settings: {
      interval_in_days: 7,
      max_occurrences: 5,
    },
    timezone: tz,
    template_id: template_id,
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let res;
    try {
      setIsLoading(true);
      res = await YouSignInitiate(signInitiateData);
      try {
        await YouSignAddSigner({
          data: {
            info: {
              first_name: currentUser?.attributes?.profile?.firstName,
              last_name: currentUser?.attributes?.profile?.lastName,
              email: currentUser?.attributes?.email,
              // phone_number: '+33601234567',
              locale: res?.data?.audit_trail_locale,
            },
            signature_level: 'electronic_signature',
            redirect_urls: {
              success: process.env.REACT_APP_MARKETPLACE_ROOT_URL,
              error: `${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/error`,
            },
            signature_authentication_mode: 'no_otp',
            fields:
              userRole === 'medium_company'
                ? [
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 8,
                        variants: { italic: false, bold: false },
                      },
                      page: 1,
                      width: 355,
                      height: 30,
                      x: 178,
                      y: 113,
                      max_length: 24,
                      optional: false,
                      question: 'Company Name / Address',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 8,
                        variants: { italic: false, bold: false },
                      },
                      page: 1,
                      width: 355,
                      height: 30,
                      x: 178,
                      y: 148,
                      max_length: 24,
                      optional: false,
                      question: 'Company Address',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 12,
                        variants: { italic: false, bold: false },
                      },
                      page: 1,
                      width: 355,
                      height: 30,
                      x: 179,
                      y: 172,
                      max_length: 17,
                      optional: false,
                      question: 'Decision maker / underwriter',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 8,
                        variants: { italic: false, bold: false },
                      },
                      page: 1,
                      width: 355,
                      height: 30,
                      x: 179,
                      y: 204,
                      max_length: 24,
                      optional: false,
                      question: 'Email of decision maker',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 8,
                        variants: { italic: false, bold: false },
                      },
                      page: 1,
                      width: 355,
                      height: 30,
                      x: 179,
                      y: 238,
                      max_length: 24,
                      optional: false,
                      question: 'Responsible sales agency for company/enterprise',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 8,
                        variants: { italic: false, bold: false },
                      },
                      page: 1,
                      width: 351,
                      height: 24,
                      x: 181,
                      y: 492,
                      max_length: 40,
                      optional: false,
                      question: 'Email for central platform user from Human Resources',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 12,
                        variants: { italic: false, bold: false },
                      },
                      page: 2,
                      width: 395,
                      height: 24,
                      x: 137,
                      y: 99,
                      max_length: 40,
                      optional: false,
                      question: 'Effective Date',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 8,
                        variants: { italic: false, bold: false },
                      },
                      page: 2,
                      width: 395,
                      height: 24,
                      x: 133,
                      y: 133,
                      max_length: 40,
                      optional: false,
                      question: 'Term',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 8,
                        variants: { italic: false, bold: false },
                      },
                      page: 2,
                      width: 368,
                      height: 24,
                      x: 164,
                      y: 232,
                      max_length: 40,
                      optional: false,
                      question: 'Other',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'signature',
                      page: 2,
                      width: 220,
                      height: 45,
                      x: 70,
                      y: 381,
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 12,
                        variants: { italic: false, bold: false },
                      },
                      page: 2,
                      width: 195,
                      height: 24,
                      x: 95,
                      y: 435,
                      max_length: 17,
                      optional: false,
                      question: 'Name',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 12,
                        variants: { italic: false, bold: false },
                      },
                      page: 2,
                      width: 195,
                      height: 24,
                      x: 93,
                      y: 466,
                      max_length: 17,
                      optional: false,
                      question: 'Date',
                    },
                  ]
                : [
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 8,
                        variants: { italic: false, bold: false },
                      },
                      page: 1,
                      width: 355,
                      height: 30,
                      x: 179,
                      y: 114,
                      max_length: 24,
                      optional: false,
                      question: 'Company Name',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 12,
                        variants: { italic: false, bold: false },
                      },
                      page: 1,
                      width: 355,
                      height: 30,
                      x: 179,
                      y: 148,
                      max_length: 100,
                      optional: false,
                      question: 'Company Address',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 8,
                        variants: { italic: false, bold: false },
                      },
                      page: 1,
                      width: 355,
                      height: 24,
                      x: 179,
                      y: 179,
                      max_length: 20,
                      optional: false,
                      question: 'Decision maker / underwriter',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 8,
                        variants: { italic: false, bold: false },
                      },
                      page: 1,
                      width: 355,
                      height: 24,
                      x: 179,
                      y: 206,
                      max_length: 24,
                      optional: false,
                      question: 'Email of decision maker',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 8,
                        variants: { italic: false, bold: false },
                      },
                      page: 1,
                      width: 355,
                      height: 24,
                      x: 179,
                      y: 238,
                      max_length: 24,
                      optional: false,
                      question: 'Responsible sales agency for company / enterprise',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 8,
                        variants: { italic: false, bold: false },
                      },
                      page: 1,
                      width: 346,
                      height: 24,
                      x: 187,
                      y: 488,
                      max_length: 40,
                      optional: false,
                      question: 'Email for main platform user from Human Resources',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 12,
                        variants: { italic: false, bold: false },
                      },
                      page: 1,
                      width: 346,
                      height: 24,
                      x: 187,
                      y: 515,
                      max_length: 40,
                      optional: false,
                      question: 'Minimum number of profiles / employees on platform',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 12,
                        variants: { italic: false, bold: false },
                      },
                      page: 2,
                      width: 398,
                      height: 24,
                      x: 132,
                      y: 198,
                      max_length: 40,
                      optional: false,
                      question: 'Effective Date',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 8,
                        variants: { italic: false, bold: false },
                      },
                      page: 2,
                      width: 398,
                      height: 24,
                      x: 133,
                      y: 223,
                      max_length: 40,
                      optional: false,
                      question: 'Term',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 8,
                        variants: { italic: false, bold: false },
                      },
                      page: 2,
                      width: 369,
                      height: 30,
                      x: 163,
                      y: 320,
                      max_length: 40,
                      optional: false,
                      question: 'Other',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'signature',
                      page: 2,
                      width: 221,
                      height: 45,
                      x: 69,
                      y: 470,
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 12,
                        variants: { italic: false, bold: false },
                      },
                      page: 2,
                      width: 197,
                      height: 24,
                      x: 93,
                      y: 530,
                      max_length: 17,
                      optional: false,
                      question: 'Name',
                    },
                    {
                      document_id: res?.data?.documents[0]?.id,
                      type: 'text',
                      font: {
                        family: 'Open Sans',
                        color: '#000000',
                        size: 12,
                        variants: { italic: false, bold: false },
                      },
                      page: 2,
                      width: 197,
                      height: 24,
                      x: 91,
                      y: 562,
                      max_length: 17,
                      optional: false,
                      question: 'Date',
                    },
                  ],
          },
          signatureRequestId: res?.data?.id,
        });
        try {
          const resp = await YouSignActivateSignature(res?.data?.id);
          try {
            const params = {
              publicData: {
                organisationcontract: {
                  yousignId: res?.data?.id,
                  signers: resp?.data?.signers[0],
                },
              },
            };
            const updatedcontractData = await updatedcontract(params);
            if (!!updatedcontractData?.actionPayload?.publicData?.organisationcontract?.yousignId) {
              window.open(resp?.data?.signers[0]?.signature_link, '_self');
            }
          } catch (error) {
            console.log('errorInUpdate :>> ', error);
          }
        } catch (error) {
          console.log('ActivateSignError', error);
        }
      } catch (error) {
        console.log('AddSignerError', error);
      }
    } catch (error) {
      console.log('InitiateSignError', error);
    } finally {
      setIsLoading(false); // Set loading state back to false after redirection or error
    }
  };
  return (
    <StaticPage {...pageMetaProps} {...pageProps}>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {props => {
          const { Topbar, Main, Footer } = props;
          return (
            <>
              <Topbar as="header" className={css.topbar}>
                <TopbarContainer />
              </Topbar>
              <Main as="main" className={css.main}>
                {sections.length === 0 && inProgress ? (
                  <LoadingSpinner />
                ) : (
                  <SectionBuilder sections={sections} options={options} />
                )}
                {emailVerified && (
                  <Modal
                    id="AuthenticationPage.tos"
                    isOpen={
                      organisationcontract?.signers?.status === 'initiated'
                        ? false
                        : userRole === 'medium_company' || userRole === 'large_company'
                        ? true
                        : contractModalOpen
                    }
                    contract={true}
                    onClose={() => setContractModalOpen(false)}
                    usePortal
                    onManageDisableScrolling={onManageDisableScrolling}
                  >
                    <div className={css.termsWrapper}></div>
                    <CompanyContract
                      isLoading={isLoading}
                      handleSubmit={handleSubmit}
                      currentUser={currentUser}
                      // inProgress={authInProgress}
                      // close={() => setContractModalOpen(false)}
                    />
                  </Modal>
                )}
              </Main>
              <Footer>
                <FooterContainer />
              </Footer>
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

export { LayoutComposer, StaticPage, SectionBuilder };

PageBuilder.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

  // from injectIntl
  // intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const currentUser = state.user.currentUser;
  return { currentUser, scrollingDisabled: isScrollingDisabled(state) };
};
const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  updatedcontract: params => dispatch(updateProfile(params)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(PageBuilder);
